import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Skill Practice: Kipping (pullups, butterfly pullups, t2b, k2e)`}</p>
    <p>{`then,`}</p>
    <p>{`Buy In: 1 Lap Run`}</p>
    <p>{`3 Rounds of:`}</p>
    <p>{`10-CTB Pullups`}</p>
    <p>{`10-Burpees`}</p>
    <p>{`10-T2B`}</p>
    <p>{`10-Burpees`}</p>
    <p>{`10-Pullups`}</p>
    <p>{`10-Burpees`}</p>
    <p>{`10-K2E`}</p>
    <p>{`10-Burpees`}</p>
    <p>{`Cash Out: 1 Lap Run`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today is your last chance to get your team registered for the
Granite Games Fall Throwdown here at The Ville on September 14th!  We’re
going to take 1 more team so sign up today!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Learn more about the Throwdown here:
 `}<a parentName="em" {...{
            "href": "https://thegranitegames.com/about-throwdowns/"
          }}>{`https://thegranitegames.com/about-throwdowns/   `}</a></em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      